import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Login from "./turf/event/admin/Login";
import Dashboard from "./turf/event/admin/Dashboard";
import Standing from "./turf/event/Standing";
import TeamManagement from "./turf/event/admin/TeamManagement";
import TeamList from "./turf/event/admin/TeamList";
import Fixtures from "./turf/event/Fixtures";
import "./App.css";


function App() {
  const isAuthenticated = !!localStorage.getItem("token"); // Check if user is logged in

  return (
    <Router>
      <Routes>
        <Route path="/beastarena/event/fixtures" element={<Fixtures />} />
        <Route path="/beastarena/event/standings" element={<Standing />} />
        <Route path="/" element={isAuthenticated ? <Dashboard /> : <Navigate to="/login" />} />
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard" element={isAuthenticated ? <Dashboard /> : <Navigate to="/login" />} />
        <Route path="/dashboard/registration" element={isAuthenticated ? <TeamManagement /> : <Navigate to="/login" />} />
        <Route path="/dashboard/participants" element={<TeamList />} />
      </Routes>
    </Router>
  );
}

export default App;

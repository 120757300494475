import React from "react";
import { useNavigate } from "react-router-dom";
import "./Dashboard.css";

const Dashboard = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("token"); 
    navigate("/login"); // Redirect to login page
  };

  return (
    <div className="dashboard-container">
      <h2>Admin Dashboard</h2>

      <div className="dashboard-options">
        {/* Register Team */}
        <div className="dashboard-card" style={{"width":"100%"}} onClick={() => navigate("/dashboard/registration")}>
          <h3>Register a Team</h3>
          <p>Add a new team with captain & players.</p>
        </div>
        {/* List Teams */}
        <div className="dashboard-card" style={{"width":"100%"}} onClick={() => navigate("/dashboard/participants")}>
          <h3>View Teams</h3>
          <p>See the list of all registered teams.</p>
        </div>
        <div className="dashboard-card" style={{"width":"100%"}} onClick={() => navigate("/beastarena/event/standings")}>
          <h3>View Table</h3>
          <p>See the standings.</p>
        </div>
        {/* Logout */}
        <button className="logout-btn" onClick={handleLogout}>
          Logout
        </button>
      </div>
    </div>
  );
};

export default Dashboard;

import React from "react";
import GroupTable from "./GroupTable";
import "./event.css";

const sampleGroup = [
  {
    name: "Portugal",
    flag: "https://flagcdn.com/w40/in.png",
    mp: 6, w: 4, d: 2, l: 0, gf: 13, ga: 5, gd: 8, points: 6,
    form: ["W", "W", "D"]
  },
  {
    name: "Croatia",
    flag: "https://flagcdn.com/w40/hr.png",
    mp: 6, w: 2, d: 2, l: 2, gf: 8, ga: 8, gd: 0, points: 3,
    form: ["L", "L", "W"]
  },
  {
    name: "Scotland",
    flag: "https://flagcdn.com/w40/gb-sct.png",
    mp: 6, w: 2, d: 1, l: 3, gf: 7, ga: 8, gd: -1, points: 0,
    form: ["L", "L", "L"]
  },
  {
    name: "Poland",
    flag: "https://flagcdn.com/w40/pl.png",
    mp: 6, w: 1, d: 1, l: 4, gf: 9, ga: 16, gd: 8, points: 6,
    form: ["W", "W", "D"]
  }
];

// Sorting function
const sortedTeams = [...sampleGroup].sort((a, b) => {
  if (b.points !== a.points) {
    return b.points - a.points; // Sort by points (higher first)
  }
  return b.gd - a.gd; // If points are equal, sort by goal difference (higher first)
});

function Standing() {
  return (
    <div>
      {/* Header */}
      <div className="header">Beast Cup Standings</div>

      {/* Group Table */}
      <div className="group-container">
        <GroupTable groupName="Group A" teams={sortedTeams} />
      </div>
      <div className="group-container">
        <GroupTable groupName="Group B" teams={sortedTeams} />
      </div>
      <div className="group-container">
        <GroupTable groupName="Group C" teams={sortedTeams} />
      </div>
    </div>
  );
}

export default Standing;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./TeamList.css";
import CONFIG from "./../../../Config";

const TeamList = () => {
  const navigate = useNavigate();
  const [teams, setTeams] = useState([]);
  const [expandedTeam, setExpandedTeam] = useState(null);

  const togglePlayers = (id) => {
    setExpandedTeam(expandedTeam === id ? null : id);
  };

  useEffect(() => {
    fetchTeams();
  }, []);

  const fetchTeams = async () => {
    try {
      const response = await fetch(`${CONFIG.BACKEND_URL}/team`, {
        method: "GET",
        headers: {
          "Authorization": `Bearer ${localStorage.getItem("token")}`
        }
      });

      if (!response.ok) {
        throw new Error("Failed to fetch teams");
      }

      const result = await response.json();
      console.log(result);
      setTeams(result.data); // Assuming response has { teams: [...] }
    } catch (error) {
      console.error("Error fetching teams:", error);
      alert("Failed to load teams.");
    }
  };


  // Delete Team
  const handleDelete = async (teamId) => {
    teamId = teamId.toLowerCase().replaceAll(" ","-");
    console.log(teamId);
    if (!window.confirm("Are you sure you want to delete this team?")) return;

    try {
      const response = await fetch(`${CONFIG.BACKEND_URL}/team/${teamId}`, {
        method: "DELETE",
        headers: {
          "Authorization": `Bearer ${localStorage.getItem("token")}`
        }
      });

      if (!response.ok) {
        throw new Error("Failed to delete team");
      }

      alert("Team deleted successfully!");
      fetchTeams(); // Refresh the list
    } catch (error) {
      console.error("Error deleting team:", error);
      alert("Failed to delete team.");
    }
  };

  const token = localStorage.getItem("token");
  return (
    <div className="team-list-container">
      <h2>Beast Cup Participants: {teams.length}</h2>
      {token ? <div className="dashboard-card" onClick={() => navigate("/dashboard")}>
          <h3>Dashboard</h3>
      </div> : null}
      
      {/* Team Table */}
      <table>
        <thead>
          <tr>
            <th>Team Name</th>
            <th>Captain</th>
            <th>Contact</th>
            <th>Players</th>
            {token ? <th>Actions</th> : null}
          </tr>
        </thead>
        <tbody>
          {teams.map((team) => (
            <tr key={team.id}>
              <td style={{"text-align":"left"}}>{team.name}</td>
              <td>{team.captain}</td>
              <td>{team.contact}</td>
              <td>
                <button className="toggle-btn" onClick={() => togglePlayers(team.id)}>
                  {expandedTeam === team.id ? "Hide" : "View"}
                </button>
                {expandedTeam === team.id && (
                  <ul className="player-list">
                    {team.players.map((player, index) => (
                      <li key={index}>{player}</li>
                    ))}
                  </ul>
                )}
              </td>
              {token ? <td><button className="delete-btn" onClick={() => handleDelete(team.name)}>X</button></td> : null}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TeamList;

import React, { useState, useEffect } from "react";
import "./Fixtures.css";

const Fixtures = () => {
  const [fixtures, setFixtures] = useState([]);
  const [isAdmin, setIsAdmin] = useState(true); // Change this based on actual admin logic

  useEffect(() => {
    // Fetch data (replace with actual API call)
    setFixtures([
      {
        id: 1,
        homeTeam: "Valencia",
        homeScore: 1,
        awayTeam: "Mallorca",
        awayScore: 0,
        status: "FT",
        date: "Sun, 30 Mar",
        time: null,
      },
      {
        id: 2,
        homeTeam: "Rayo Vallecano",
        awayTeam: "Espanyol",
        status: "Scheduled",
        date: "Sat, 5 Apr",
        time: "12:30 AM",
      },
      {
        id: 1,
        homeTeam: "Valencia",
        homeScore: 1,
        awayTeam: "Mallorca",
        awayScore: 0,
        status: "FT",
        date: "Sun, 30 Mar",
        time: null,
      },
      {
        id: 2,
        homeTeam: "Rayo Vallecano",
        awayTeam: "Espanyol",
        status: "Scheduled",
        date: "Sat, 5 Apr",
        time: "12:30 AM",
      },
      {
        id: 1,
        homeTeam: "Valencia",
        homeScore: 1,
        awayTeam: "Mallorca",
        awayScore: 0,
        status: "FT",
        date: "Sun, 30 Mar",
        time: null,
      },
      {
        id: 2,
        homeTeam: "Rayo Vallecano",
        awayTeam: "Espanyol",
        status: "Scheduled",
        date: "Sat, 5 Apr",
        time: "12:30 AM",
      },
      {
        id: 1,
        homeTeam: "Valencia",
        homeScore: 1,
        awayTeam: "Mallorca",
        awayScore: 0,
        status: "FT",
        date: "Sun, 30 Mar",
        time: null,
      },
      {
        id: 2,
        homeTeam: "Rayo Vallecano",
        awayTeam: "Espanyol",
        status: "Scheduled",
        date: "Sat, 5 Apr",
        time: "12:30 AM",
      },
    ]);
  }, []);

  const handleEdit = (id) => {
    alert(`Edit match ${id}`);
    // Add logic for updating match scores or marking match as finished
  };

  return (
    <div className="fixtures-container">
      <h2 className="fixtures-title">Match Fixtures</h2>
      <div className="fixtures-list">
        {fixtures.map((match) => (
          <div key={match.id} className="match-card">
            <div className="match-info">
              <span className="team-name">{match.homeTeam}</span>
              <div className="score">
                {match.status === "FT" ? (
                  <span className="score-text">{match.homeScore} - {match.awayScore}</span>
                ) : (
                  <span className="match-time">{match.time}</span>
                )}
              </div>
              <span className="team-name">{match.awayTeam}</span>
            </div>
            <div className="match-footer">
              <span className="match-date">{match.date}</span>
              {isAdmin && <button className="edit-btn" onClick={() => handleEdit(match.id)}>Edit</button>}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Fixtures;
import React from "react";
import "./GroupTable.css";

const GroupTable = ({ groupName, teams }) => {
  return (
    <div className="group-container">
      <h2 className="group-title">{groupName}</h2>
      <div className="group-table">
        {/* Table Header */}
        <div className="table-row header">
          <span className="club">Team</span>
          <span>MP</span>
          <span>W</span>
          <span>D</span>
          <span>L</span>
          <span>GF</span>
          <span>GA</span>
          <span>GD</span>
          <span className="points">Pts</span>
          <span className="last-5">Record</span>
        </div>

        {/* Table Rows */}
        {teams.map((team, index) => (
          
          <div
            className={`table-row ${index % 2 !== 0 ? "dark-row" : ""}`}
            key={team.name}
          >
            <span className="club">
              {team.name}
            </span>
            <span>{team.mp}</span>
            <span>{team.w}</span>
            <span>{team.d}</span>
            <span>{team.l}</span>
            <span>{team.gf}</span>
            <span>{team.ga}</span>
            <span>{team.gd}</span>
            <span className="points">{team.points}</span>
            <span className="last-5">
              {team.form.map((result, i) => (
                <span key={i} className={`form-icon ${result}`}>
                  {result === "W" ? "✅" : result === "L" ? "❌" : "➖"}
                </span>
              ))}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GroupTable;

import React, { useState } from "react";
import "./TeamManagement.css";
import { useNavigate } from "react-router-dom";
import CONFIG from "./../../../Config"


const TeamManagement = () => {
  const navigate = useNavigate();
  const [newTeam, setNewTeam] = useState({
    name: "",
    captain: "",
    contact: "",
    players: ["Player1", "Player2", "Player3", "Player4", "Player5", "Player6"], // Reset to minimum 6 players
  });

  // Handle text input changes
  const handleChange = (e) => {
    setNewTeam({ ...newTeam, [e.target.name]: e.target.value });
  };

  // Handle player input changes
  const handlePlayerChange = (index, value) => {
    const updatedPlayers = [...newTeam.players];
    updatedPlayers[index] = value;
    setNewTeam({ ...newTeam, players: updatedPlayers });
  };

  // Add a new player input field
  const addPlayerField = () => {
    setNewTeam({ ...newTeam, players: [...newTeam.players, ""] });
  };

  // Remove a player input field (minimum 6)
  const removePlayerField = (index) => {
    if (newTeam.players.length > 6) {
      const updatedPlayers = newTeam.players.filter((_, i) => i !== index);
      setNewTeam({ ...newTeam, players: updatedPlayers });
    }
  };
  
  const resetForm = () => {
    setNewTeam({
      name: "",
      captain: "",
      contact: "",
      players: ["Player1", "Player2", "Player3", "Player4", "Player5", "Player6"], // Reset to minimum 6 players
    });
  };

  // Add a new team
  const handleAddTeam = async () => {
    if (!newTeam.name || !newTeam.captain || !newTeam.contact|| newTeam.players.some(p => p.trim() === "")) {
      alert("Please fill in all fields correctly.");
      return;
    }
    console.log(localStorage);
    try {
      const response = await fetch(`${CONFIG.BACKEND_URL}/team`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("token")}`, // Send JWT token
        },
        body: JSON.stringify(newTeam),
      });
  
      if (!response.ok) {
        console.log(response)
        throw new Error("Failed to register team");
      }
  
      const result = await response.json();
        console.log(result);
        alert("Team Added!");
        resetForm();
      } catch (error) {
        console.log(error);
        alert("Error: " + error.message);
      }
  };

  return (
    <div className="team-container">
      <h2>Team Registration</h2>
        
      {/* Add New Team Form */}
      <div className="add-team">
        <div className="input-group">
          <label>Team Name:</label>
          <input type="text" name="name" value={newTeam.name} onChange={handleChange} />
        </div>
        <div className="input-group">
          <label>Captain Name:</label>
          <input type="text" name="captain" value={newTeam.captain} onChange={handleChange} />
        </div>
        <div className="input-group">
          <label>Contact:</label>
          <input type="text" name="contact" value={newTeam.contact} onChange={handleChange} />
        </div>

        {/* Player List Input */}
        <div className="player-list">
          <label>Players:</label>
          {newTeam.players.map((player, index) => (
            <div key={index} className="player-input">
              <input
                type="text"
                value={player}
                onChange={(e) => handlePlayerChange(index, e.target.value)}
                placeholder={`Player ${index + 1}`}
              />
              {newTeam.players.length > 6 && (
                <button className="remove-btn" onClick={() => removePlayerField(index)}>✖</button>
              )}
            </div>
          ))}
          <button className="add-player-btn" onClick={addPlayerField}>+ Add Player</button>
        </div>

        <button className="submit-btn" onClick={handleAddTeam}>Add Team</button>
      </div>

      <div className="dashboard-card" style={{"width": "50%"}} onClick={() => navigate("/dashboard")}>
          <h3>Dashboard</h3>
      </div>
    </div>
  );
};

export default TeamManagement;
